import {
  Controller
} from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ['instaUsername', 'instaUsername1', 'instaUsernameCopy', 'fullName', 'email', 'spinner', 'lock', 'stripePublicKey', 'locale']

  connect() {

    this.stripe = Stripe(this.stripePublicKeyTarget.value)
    console.log(this.stripePublicKeyTarget.value)
    const elements = this.stripe.elements({
      fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Mukta:400,600,700&display=swap',
      }],
    })

    const elementsStyles = {
      base: {
        fontFamily: 'Mukta, sans-serif',
        fontSize: '16px',
        fontWeight: '600',
        fontSmoothing: 'antialiased',
        color: '#696871',
        '::placeholder': {
          color: '#9c9aaa'
        }
      },
      invalid: {
        color: "#ff5252",
        iconColor: "#ff5252",
      },
    }

    /* MORE STRIPE ELEMENTS ACTIONS */
    this.cardNumber = elements.create("cardNumber", {
      style: elementsStyles
    })
    this.cardNumber.mount("#subscription_step3_form_cardNumber")

    this.expiration = elements.create("cardExpiry", {
      style: elementsStyles
    })
    this.expiration.mount("#subscription_step3_form_expiration")

    this.cvcNumber = elements.create("cardCvc", {
      style: elementsStyles
    })
    this.cvcNumber.mount("#subscription_step3_form_cvcNumber")

    /* Set up event listeners on card */
    this.cardNumber.addEventListener('change', ({
      error
    }) => {
      this.displayError(error)
    })
    this.expiration.addEventListener('change', ({
      error
    }) => {
      this.displayError(error)
    })
    this.cvcNumber.addEventListener('change', ({
      error
    }) => {
      this.displayError(error)
    })
  }

  displayError(error) {
    const errorContainer = document.getElementById('error')
    if (error) {
      errorContainer.classList.remove('hide');
      errorContainer.classList.add('d-block');
      errorContainer.innerText = error;
    } else {
      errorContainer.classList.add('hide');
      errorContainer.classList.remove('d-block');
      errorContainer.innerText = '';
    }
  }

  firstStep(event) {
    console.log('first step')
    event.preventDefault()
    new bootstrap.Modal(document.getElementById('stepModal')).show()
    if (this.instaUsername1Target.value) {
      this.instaUsernameCopyTarget.innerText = `${this.instaUsername1Target.value}`
      this.username = this.instaUsername1Target.value
      this.instaUsername1Target.value = ''
    } else {
      this.instaUsernameCopyTarget.innerText = `${this.instaUsernameTarget.value}`
      this.username = this.instaUsernameTarget.value
      this.instaUsernameTarget.value = ''
    }
  }

  loadingActive() {
    this.lockTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
  }

  loadingDisactive() {
    this.lockTarget.classList.remove('hidden')
    this.spinnerTarget.classList.add('hidden')
  }

  pay(event) {
    this.loadingActive()
    // Setup authenticity token
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    event.preventDefault()

    if(!this.fullNameTarget.value) {
      this.displayError('Please enter your full name')
      this.loadingDisactive()
      return
    }
    if(!this.emailTarget.value) {
      this.displayError('Please enter your email')
      this.loadingDisactive()
      return
    }
    if(!this.username) {
      this.displayError('Please enter your username')
      this.loadingDisactive()
      return
    }


    /* Create your 'card' payment method */
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      /* Reference: https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details */
      billing_details: {
        name: this.fullNameTarget.value,
        email: this.emailTarget.value
      },
    }).then((result) => {
      if (result.error) {
        this.displayError(result.error);
        this.loadingDisactive()
        console.error('Error creating Stripe Payment Method: ', result.error)
      }
      else if (result.paymentMethod.card.funding === 'prepaid') {
        console.log('prepaid')
        this.displayError("Questo tipo di carta di pagamento non è accettato dai nostri servizi");
      }
      else {
        /* Proceed to next step of creating customer and subscription */
        this.stripePaymentMethodHandler(result.paymentMethod)
      }
    })
  }

  stripePaymentMethodHandler(paymentMethod) {
    axios.post(`/pay?locale=${this.localeTarget.value}` , {
      user: {
        email: this.emailTarget.value,
        full_name: this.fullNameTarget.value,
        username: this.username
      },
      stripe_plan: {
        payment_method_id: paymentMethod.id
      }
    }).then(response => {
      if (response.data.error) {
        this.displayError(response.data.error)
        this.loadingDisactive()
        axios.post('/stop-plan?destroy=true', {
          user: {
            email: this.emailTarget.value
          }
        })
      } else {
        this.customerId = response.data.customer_id
        this.stripe.confirmCardPayment(response.data.intent.client_secret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: this.fullNameTarget.value,
              email: this.emailTarget.value
            },
          },
        }).then((result) => {
          if (result.error) {
            this.displayError(result.error.message)
            console.warn(result.error)
            this.loadingDisactive()
            axios.post('/stop-plan?destroy=true', {
              user: {
                email: this.emailTarget.value
              }
            })
          } else {
            axios.post(`/sign-in-user?customer_id=${this.customerId}&locale=${this.localeTarget.value}`, {
              order: {
                profile: this.profile,
              }
            }).then(response => {
              window.location.replace(response.data.redirect_url)
            })
          }
        })
      }
    })
  }
}